@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';


@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

$subnav-hero-bg: $nero;
$subnav-hero-link-padding: 0.9rem 1rem;
$subnav-hero-font-size: 1rem;
$subnav-hero-link-opacity: 0.75;
$subnav-hero-height: 300px;
$subnav-hero-transition: all 0.35s ease-in-out;

header 	{
  text-align:center;
  background-color:$nero;
  margin-bottom: 0rem;

}

body{
  background: #f9f9f9;
}

a {
  color: $rosso;
  &:hover {
    color: scale-color($rosso, $lightness: -54%);
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

.rosso2
{
  color: $rosso2;
}

.top-bar
{
  align-items: center;
  justify-content: center;
  background: $subnav-hero-bg;
  text-align: center !important;
  margin: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  ul{
    background: $subnav-hero-bg;
  }
  li {
    float: none;
    display: inline-block;
    a {
      padding: $subnav-hero-link-padding;
      font-size: $subnav-hero-font-size;
      display: block;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      &.is-active {
        background: rgba($rosso, $subnav-hero-link-opacity);
      }

      &:hover {
        background: rgba($rosso, $subnav-hero-link-opacity);
        transition: $subnav-hero-transition;
      }
    }
  }

}

.rosso{
  color: $rosso !important;
}

.maiuscolo{
  text-transform: uppercase;
}

.sottomenu
{
  background: $grigio !important;
}

.sfondogrigio
{
  background: #2c2c2c !important;

  border: 3px solid $rosso;

}

.sfondobianco
{
  background: #eaeaea !important;
}

.miofooter {
  background-color:$nero;
  color: white !important;
  font-size: 1em;
  padding-left: 3em !important;
  padding-bottom: 1em !important;
  padding-top: 1em !important;
}

//----serve per evitare che il menu responsive appaia appena si carica la pagina

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}
//----------------------------------------------------------------------------------


.icone
{
  font-size: 20px !important;
  padding-top: 0.3em !important;
  padding-right: 0.3em !important;

}

.linkcontattifooter{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    color: $rosso !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#cincila{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    background: $cincila !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#vedova{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    background: $vedova !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#principessa{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    background: $principessa !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#cavallino {
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    color: $nero !important;
    background: $cavallino !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#scugnizza{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    background: $scugnizza !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#campanelli{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    background: $campanelli !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#savoy{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    background: $savoy !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

#tabarin{
  color: white !important;
  &:visited{
    color: white !important;
  }
  &:hover
  {
    background: $tabarin !important;
    -o-transition:.4s;
    -ms-transition:.4s;
    -moz-transition:.4s;
    -webkit-transition:.4s;
    transition:.4s;
  }
}

.cincila{
  color: $cincila !important;
}

.vedova{
  color: $vedova !important;
}

.campanelli
{
  color: $campanelli !important;
}

.cavallino
{
  color: $cavallino !important;
}

.scugnizza
{
  color: $scugnizza !important;
}

.principessa
{
  color: $principessa !important;
} 

.savoy
{
  color: $savoy !important;
}

.tabarin
{
  color: $tabarin !important;
}

.divpdf
{
  margin-bottom: 1em !important;
  margin-top: 1em !important;
}

.elenco {
  width: 330px !important;
  float: left;
}

.testonero{
  color: $nero !important;
}


//per footer

body {
  height: auto;
}